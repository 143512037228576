import React from "react";
import introLogo from "../intro-logo.png";
import { Link } from "react-scroll";

const Hero = (props) => {
  const data = props.portfolioData;

  return (
    <section className="site-intro site-intro--with-clipmask background">
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />
      <div className="container">
        <div className="site-intro__wrapper">
          <div className="site-intro__main">
            <div className="site-intro__intro">
              <h1 className="site-intro__name"> {data.name} </h1>
              <h2 className="site-intro__heading"> I'm a {data.role}. </h2>
              <hr />
              <h2 className="site-intro__primary">
                {" "}
                {data.intro}{" "}
                <strong>
                  <em>{data.aurthor}</em>
                </strong>{" "}
              </h2>
            </div>
            <div className="site-intro__action">
              <div className="site-intro__scroll_animation">
                <Link
                  to="menu"
                  href="#menu"
                  spy={true}
                  smooth={true}
                  offset={-15}
                  duration={500}
                >
                  <span></span>
                </Link>
              </div>
              <div className="site-intro__scroll_text">
                <Link
                  to="menu"
                  href="#menu"
                  spy={true}
                  smooth={true}
                  offset={-15}
                  duration={500}
                >
                  <span>Scroll</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="site-intro__logo-wrapper">
            <img src={introLogo} alt="" className="site-intro__logo" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
