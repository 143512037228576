const portfolioData = {
  imageUrl: "http://localhost:3000/",
  header: { logoUp: "images/up-arrow.svg", logoProfile: "images/profile.svg" },
  hero: {
    name: "RABJOT AUJLA",
    role: "software engineer with a hint of philosophy",
    intro: "Somewhere, something incredible is waiting to be known - ",
    aurthor: "Carl Sagan",
  },
  menu: [
    {
      class: "skills",
      name: "Skills",
      logo: "images/menu/skills.svg",
    },
    {
      class: "projects",
      name: "Projects",
      logo: "images/menu/projects.svg",
    },
    {
      class: "work",
      name: "Work",
      logo: "images/menu/work.svg",
    },
    {
      class: "education",
      name: "Education",
      logo: "images/menu/education.svg",
    },
    {
      class: "contact",
      name: "Contact",
      logo: "images/menu/contact.svg",
    },
  ],
  projects: [
    {
      id: 0,
      title: "Power BI analysis of Kaggle dataset of crimes in San Fran",
      imageUrl: "images/portfolio/PowerBI.png",
      imageLink:
        "https://app.powerbi.com/view?r=eyJrIjoiZThjODZiZGYtZGVjNC00MWNiLTk0ZTItN2I5YTcyY2VhNWUxIiwidCI6IjcyNDNlZmIxLWRkNGMtNDkyNy04NTVkLWMyMWE0ZjYyY2I1OSJ9",
      link:
        "https://app.powerbi.com/view?r=eyJrIjoiZThjODZiZGYtZGVjNC00MWNiLTk0ZTItN2I5YTcyY2VhNWUxIiwidCI6IjcyNDNlZmIxLWRkNGMtNDkyNy04NTVkLWMyMWE0ZjYyY2I1OSJ9",
      codeLink: "",
      description:
        "This project is to create an online dashbaord with filters using PowerBI",
      technology: ["Power BI"],
      codeButton: "images/portfolio/coding_button.svg",
    },
    {
      id: 1,
      title: "Indeed Data Scraping/Minning",
      imageUrl: "images/portfolio/indeed.png",
      imageLink: "https://github.com/aujlarj/Job_Search_Automation",
      link: "",
      codeLink:
        "https://github.com/aujlarj/Job_Search_Automation/tree/master/Jupyter%20Notebook",
      description:
        "This projects scraps data from indeed and then creates an analysis based on job location, count, title, description, etc",
      technology: [
        "Python",
        "Selenium, BeautifulSoup",
        "Pandas, Numpy",
        "Matplotlib, Seaborn",
        "Jupyter Notebook",
      ],
      codeButton: "images/portfolio/coding_button.svg",
    },
    {
      id: 2,
      title: "Data Analysis (for STEM Fellowship)",
      imageUrl: "images/portfolio/stem.jpg",
      imageLink: "https://stemfellowship.org/",
      link:
        "https://journal.stemfellowship.org/doi/pdf/10.17975/sfj-2019-002?src=recsys&",
      codeLink:
        "https://drive.google.com/file/d/0B9Y33YIFriDRUVBGYUNHY2l3MjRQalNOLXVuMlBqVmV6VFU0/view",
      description:
        "Nominated as a finalist in Big Data competition held by STEM Fellowship on Recreational Drug use/affect",
      technology: ["Tableau", "Excel", "Python, BeautifulSoup"],
      codeButton: "images/portfolio/coding_button.svg",
    },
    {
      id: 3,
      title: "Image Processing (for Autonomous Underwater Vehicle(AUVic) Club)",
      imageUrl: "images/portfolio/AUVic.png",
      imageLink: "https://onlineacademiccommunity.uvic.ca/auvic/",
      link: "https://onlineacademiccommunity.uvic.ca/auvic/vehicles/polaris/",
      codeLink: "https://github.com/uvic-auvic/opencv",
      description:
        "AUVic is a robotics club located in the University of Victoria. I was part of the software team that focus on improving the submarine's image processing",
      technology: [
        "Python",
        "OpenCV, LabelImg",
        "TensorFlow, Inception ML Model",
      ],
      codeButton: "images/portfolio/coding_button.svg",
    },
    {
      id: 4,
      title: "Data Analysis of Crimes in Vancouver",
      imageUrl: "images/portfolio/crimesInVanc.png",
      imageLink:
        "https://colab.research.google.com/drive/10_ISzd5QcSJNqBqiZJYlYxP2u8unhqFJ?usp=sharing",
      link: "",
      codeLink:
        "https://colab.research.google.com/drive/10_ISzd5QcSJNqBqiZJYlYxP2u8unhqFJ?usp=sharing",
      description:
        "This project analyze a Kaggel Dataset of crimes in Vancouver",
      technology: [
        "Python",
        "Pandas, Numpy",
        "Matplotlib, Seaborn",
        "Google Colab",
      ],
      codeButton: "images/portfolio/coding_button.svg",
    },
    {
      id: 5,
      title: "Data Analysis of Homelessness in Canada",
      imageUrl: "images/portfolio/homelessness.png",
      imageLink: "https://www.homelesshub.ca/",
      link:
        "https://drive.google.com/file/d/107jVu5nFuEk4SUsRnSPW4VAjgQWzjEUj/view",
      codeLink: "",
      description:
        "This project explore the existing pattrens of homelessness and the mental health of the homeless people in Canada",
      technology: [
        "Google BigQuery",
        "Tableau",
        "Jupyter Notebook",
        "Principal Component Analysis",
      ],
      codeButton: "images/portfolio/coding_button.svg",
    },
  ],
  skills: [
    {
      id: "language",
      name: "Languages",
      logo1: "images/skills/L_a.svg",
      logo2: "images/skills/L_b.svg",
      tech_exp: [
        ["SQL", "Advanced"],
        ["Python", "Advanced"],
        ["Javascript", "Intermediate"],
        ["C#", "Intermediate"],
        ["HTML", "Intermediate"],
        ["CSS & SASS", "Intermediate"],
        ["C & C++ ", "Novice"],
        ["Java", "Novice"],
      ],
    },
    {
      id: "expertise",
      name: "Areas of Expertise",
      logo1: "images/skills/EX_a.svg",
      logo2: "images/skills/EX_b.svg",
      tech_exp: [
        ["Object Oriented Programming", "Advanced"],
        ["Agile and Scrum Methodologies", "Advanced"],
        ["Git Version Control", "Intermediate"],
        ["Google Could Platform", "Intermediate/Novice"],
        ["Amazon Web Services", "Novice"],
        ["Microsoft Azure", "Fundamental Awareness"],
      ],
    },
    {
      id: "data_science",
      name: "Data Science",
      logo1: "images/skills/ML_a.svg",
      logo2: "images/skills/ML_b.svg",
      tech_exp: [
        ["Pandas & Numpy", "Advanced"],
        ["Matplotlib & Seaborn", "Advanced"],
        ["Scikit", "Advanced"],
        ["TensorFlow", "Intermediate"],
        ["Tableau & Power BI", "Intermediate"],
        ["OpenCV", "Intermediate"],
        ["Hadoop Ecosystem", "Novice"],
      ],
    },
    {
      id: "full_stack",
      name: "Full Stack",
      logo1: "images/skills/FS_a.svg",
      logo2: "images/skills/FS_b.svg",
      tech_exp: [
        ["Relational Databases", "Advanced"],
        ["Node.js", "Intermediate"],
        ["Electron", "Intermediate"],
        ["Bootstrap", "Intermediate"],
        ["React JS", "Intermediate"],
        ["NoSQL Databases", "Novice"],
        ["Django", "Fundamental Awareness"],
        ["jQuery", "Fundamental Awareness"],
      ],
    },
  ],
  timeline: [
    {
      scope: "work",
      title: "Software Developer",
      date: "August 2020 - Present",
      description: [
        "Designed, developed, and maintained both new and existing code, ranging from client-side development to server-side code",
        "Built features and applications while adhering to security and data protection standards",
        "Created external libraries with Object-Oriented design and descriptive comments",
        "Wrote and maintained documentation for new and existing features"
      ],
      company: "XBO Technologies",
      companyWebsite: "https://xbo-t.com/",
      image: "images/timeline/c-sharp.svg",
    },
    {
      scope: "work",
      title: "Software Developer",
      date: "Spetmeber 2019 - August 2020",
      description: [
        "Created a PostgreSQL relational database adhering to the company’s business model and needs",
        "Developed a Node.js(JavaScript) app using Electron framework, connected to the relational database",
        "Build a command-line python application to transfer all the unstructured data to a relational database",
        "Created various types of data visualization for KPI’s and to understand existing patterns in data",
        "Educated co-workers from different academic backgrounds about usage and benefits of cloud computing",
      ],
      company: "SmokeyBay Seafood Group",
      companyWebsite: "https://www.smokeybay.com/",
      image: "images/timeline/software_developer.svg",
    },
    {
      scope: "education",
      title: "Graduated with B.S.Eng",
      date: "April 2020",
      description: [],
      company: "University of Victoria",
      companyWebsite: "https://www.uvic.ca/",
      image: "images/timeline/certificate.svg",
    },
    {
      scope: "work",
      title: "Data Mining (Co-op)",
      date: "September 2018 - April 2019",
      description: [
        "Gained knowledge of using Compute Engine, Data lab, Big Query, Cloud Storage and other GCP tools",
        "Developed test ML models using Xgboost, TensorFlow and Random Forest from Big Data subsets",
        "Built C# application which parses, clean and uploads data from .txt files into database",
        "Converted existing Crystal and Excel reports into SSRS and Power BI reports",
        "Worked within the Software Life Cycle using Agile methodology",
      ],
      company: "Teck Resources Ltd.",
      companyWebsite: "https://www.teck.com/",
      image: "images/timeline/data_mining.svg",
    },
    {
      scope: "work",
      title: "Data Analyst (Co-op)",
      date: "January 2016 - December 2016",
      description: [
        "Used SQL Server Management Studio to build queries and stored procedures from different databases",
        "Built SSRS reports for Engineers to check the efficiency and effectiveness of various systems",
        "Modified existing SQL stored procedures and SSIS packages to meet requirements of PMP project",
        "Compare different methods of reporting to discover key performance indicators",
        "Offered help to other teammates in area of my expertise",
      ],
      company: "Teck Resources Ltd.",
      companyWebsite: "https://www.teck.com/",
      image: "images/timeline/data_analyst.svg",
    },
  ],
  education: [
    {
      class: "uvic",
      institute: ["University of Victoria", "https://www.uvic.ca/"],
      certification: [
        "Bachelor of Software Engineering",
        "https://www.uvic.ca/engineering/software/index.php",
      ],
      description: [
        [
          "Specializaion in Data Mining, Machine Learning and AI",
          "https://www.uvic.ca/engineering/software/current-students/specializations/index.php#acc-data-mining-and-analysis-artificia",
        ],
      ],
      logo: "images/education/uvic.jpg",
      date: "Graduated on April 2020",
    },
    {
      class: "coursera",
      institute: ["Coursera", "https://www.coursera.org/"],
      certification: [
        "Machine Learning with TensorFlow Google Cloud Platform",
        "https://www.coursera.org/specializations/machine-learning-tensorflow-gcp",
      ],
      description: [
        [
          "How Google does Machine Learning",
          "https://www.coursera.org/account/accomplishments/verify/FXUV5W6ACMKW",
        ],
        [
          "Intro to TensorFlow",
          "https://www.coursera.org/account/accomplishments/verify/RYL5RYH5UCEC",
        ],
        [
          "Lauching into Machine Learning",
          "https://www.coursera.org/account/accomplishments/verify/Y8AZQ884TGMN",
        ],
      ],
      logo: "images/education/coursera.jpg",
      date: "Course completed on April 2019",
    },
  ],
  footer: {
    qoute: '"Everyday living is learning."',
    facebook: [
      "images/footer/facebook.svg",
      "https://www.facebook.com/aujla.rj",
    ],
    github: ["images/footer/github.svg", "https://www.github.com/aujlarj"],
    linkedin: [
      "images/footer/linkedin.svg",
      "https://www.linkedin.com/in/rabjot-aujla-7b8054120",
    ],
    rights: "All rights reserved © 2020",
  },
};

export default portfolioData;
