import React, { Fragment, Component } from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import AboutMe from "./components/aboutMe";
import Projects from "./components/Projects/Projects";
import ProjectList from "./components/Projects/ProjectList_old";
import ProjectInfo from "./components/Projects/ProjectInfo";
import Skills from "./components/Skills";
import Work from "./components/work";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import portfolioData from "./data/portfolio";
import Modal from "./components/Modal/Modal";
import Education from "./components/Education";
import Menu from "./components/menu";
import "./sass/main.scss";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenModal: false,
      projectInfo: [],
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickDocument);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickDocument);
  }

  handleClickDocument = (e) => {
    if (e.target.classList.contains("modal")) {
      this.setState({ isOpenModal: false });
      document.querySelector("body").classList.remove("no-scroll");
    }
  };

  handleClick = (id) => {
    this.setState({ isOpenModal: true });
    document.querySelector("body").classList.add("no-scroll");
    this.getInfo(id);
  };

  getInfo = (id) => {
    const data = portfolioData.projects.filter((proj) => proj.id === id);
    this.setState({ projectInfo: data });
  };

  render() {
    const data = portfolioData;
    const { projectInfo, isOpenModal } = this.state;
    let projectList, projectItem;
    projectList = data.projects.map((proj, key) => {
      return (
        <ProjectList
          key={proj.id}
          {...proj}
          handleClickProject={this.handleClick.bind(this, proj.id)}
        />
      );
    });

    projectItem = projectInfo.map((info, i) => (
      <ProjectInfo key={i} {...info} />
    ));

    return (
      <Fragment>
        <Header portfolioData={portfolioData.header} />
        <main className="site-main" id="home">
          <Hero portfolioData={portfolioData.hero} id="home" />
          <Menu portfolioData={portfolioData.menu}></Menu>
          <Skills portfolioData={portfolioData.skills} />
          <Work portfolioData={portfolioData.timeline} />
          <Projects portfolioData={portfolioData.projects}> </Projects>
          <Education portfolioData={portfolioData.education}></Education>
          <AboutMe></AboutMe>
          <Contact />
          {isOpenModal && <Modal>{projectItem}</Modal>}
        </main>
        <Footer portfolioData={portfolioData.footer} />
      </Fragment>
    );
  }
}

export default App;
