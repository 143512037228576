import React, { Component } from "react";

const Footer = (props) => {
  const data = props.portfolioData;

  return (
    <footer className="site-footer background site-footer--with-clipmask">
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />
      <div className="site-footer__quote">{data.qoute}</div>
      <div className="site-social">
        <div className="site-social__list">
          <a
            href={data.facebook[1]}
            className="site-social__link"
            target="_blank"
          >
            <img src={data.facebook[0]} alt="facebook" />
          </a>
        </div>
        <div className="site-social__list">
          <a
            href={data.github[1]}
            className="site-social__link"
            target="_blank"
          >
            <img src={data.github[0]} alt="github" />
          </a>
        </div>
        <div className="site-social__list">
          <a
            href={data.linkedin[1]}
            className="site-social__link"
            target="_blank"
          >
            <img src={data.linkedin[0]} alt="linkedin" />
          </a>
        </div>
      </div>
      <div className="site-footer__copyright">{data.rights}</div>
    </footer>
  );
};

export default Footer;
