import React, { Component } from "react";
import ProjectInfo from "./ProjectInfo";

// const Projects = (props) => {
//   let styles = {
//     textAlign: "center",
//     margin: "5rem",
//   };

//   return (
//     <section className="site-section site-standard" id="projects">
//       <div className="container">
//         <h1 className="site-heading"> My Recent Projects </h1>
//         <div className="row">{props.children}</div>
//       </div>
//     </section>
//   );
// };

// export default Projects;

class Projects extends Component {
  state = {};
  render() {
    const data = this.props.portfolioData;

    console.log("data", data);

    let projectWork = data.map((work, i) => (
      <ProjectInfo
        key={i}
        title={work.title}
        description={work.description}
        technology={work.technology}
        codeLink={work.codeLink}
        link={work.link}
        imageUrl={work.imageUrl}
        imageLink={work.imageLink}
        codeButton={work.codeButton}
      ></ProjectInfo>
    ));

    return (
      <section className="site-section site-standard" id="projects">
        <div className="container">
          <h1 className="site-heading"> My Recent Projects </h1>
          <div className="project_wrapper">{projectWork}</div>
        </div>
      </section>
    );
  }
}

export default Projects;
