import React, { Component } from "react";
import { Link } from "react-scroll";

class Menu extends Component {
  render() {
    const data = this.props.portfolioData;
    // console.log(data);

    let menulist = data.map((item, i) => (
      <Link
        key={i}
        className={"card " + item.class}
        to={item.class}
        href={"#" + item.class}
        spy={true}
        smooth={true}
        offset={-50}
        duration={500}
      >
        <div className="overlay"></div>
        <div className="circle">
          <img src={item.logo} alt="Logo" />
        </div>
        <p>{item.name}</p>
      </Link>
    ));

    return (
      <section className="site-section" id="menu">
        <div className="container">
          <h1 className="site-heading"> Menu </h1>
          <div className="menu_cards">{menulist}</div>
        </div>
      </section>
    );
  }
}

export default Menu;
