import React, { Component } from "react";

class Education extends Component {
  state = {};
  render() {
    const data = this.props.portfolioData;

    let education = data.map((ed_item, i) => (
      <div className="education__wrapper">
        <div key={i} className={"education__container " + ed_item.class}>
          <div className="education__item logo">
            <img src={ed_item.logo} alt="Logo" />
          </div>
          <div className="education__container__item institute">
            <h2>
              <a href={ed_item.institute[1]} target="_blank">
                {ed_item.institute[0]}
              </a>
            </h2>
          </div>
          <div className="education__container__item certification">
            <h3>
              <a href={ed_item.certification[1]} target="_blank">
                {ed_item.certification[0]}
              </a>
            </h3>
          </div>
          <div className="education__container__item description">
            <ul>
              {ed_item.description.map((desp_item, i) => (
                <li key={i}>
                  <a href={desp_item[1]} target="_blank">
                    {desp_item[0]}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={"education__date-" + ed_item.class}>
          {" "}
          <em> {ed_item.date} </em>
        </div>
      </div>
    ));

    return (
      <section className="site-section" id="education">
        <div className="container">
          <h1 className="site-heading"> Education </h1> {education}{" "}
        </div>
      </section>
    );
  }
}

export default Education;
