import React, { Component, Fragment } from "react";
import "react-circular-progressbar/dist/styles.css";

class Skills extends Component {
  state = {
    language: false,
    data_science: false,
    full_stack: false,
    expertise: false,
  };

  handleMouseEnter = (id) => {
    const newState = this.state;
    newState[id] = true;
    this.setState(newState);
  };

  handleMouseLeave = (id) => {
    const newState = this.state;
    newState[id] = false;
    this.setState(newState);
  };

  render() {
    const data = this.props.portfolioData;
    // const { isHoverOn } = this.state;
    // console.log(this.state);

    let skillList = data.map((skill, i) => (
      <div
        className="skills-card"
        key={i}
        id={skill.id}
        onMouseEnter={() => this.handleMouseEnter(skill.id)}
        onMouseLeave={() => this.handleMouseLeave(skill.id)}
      >
        <img src={this.state[skill.id] ? skill.logo2 : skill.logo1} alt="" />
        <div className="skills-card__title">{skill.name}</div>
        <table className="skills-card__table">
          <tbody className="skills-card__tbody">
            {skill.tech_exp.map((item, i) => (
              <tr key={i}>
                <td className="skills-card__technology">{item[0]}</td>
                <td className="skills-card__experience">{item[1]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ));

    return (
      <section className="site-section" id="skills">
        <div className="container">
          <h1 className="site-heading"> Skills </h1>{" "}
          <div className="layout" style={{ padding: "1.5rem" }}>
            <div className="skills-wrapper">{skillList}</div>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;

// const Skills = (props) => {
//   const { skills: data } = props.portfolioData;
//-------------------
// let skillList;
// skillList = data.skills.map((s, i) => (
//   <div className="col-2" key={i}>
//     <div className="site-skill">
//       <div className="site-skill__canvas-wrapper">
//         <VisibilitySensor>
//           {({ isVisible }) => {
//             const percentage = isVisible ? s.percentage : 0;
//             return (
//               <CircularProgressbar
//                 value={percentage}
//                 text={`${percentage}%`}
//                 className="site-skill__canvas"
//                 styles={buildStyles({
//                   // Text size
//                   textSize: "2rem",
//                   // How long animation takes to go from one percentage to another, in seconds
//                   pathTransitionDuration: 1,
//                 })}
//               />
//             );
//           }}
//         </VisibilitySensor>
//       </div>
//       <div className="site-skill__text"> {s.name}</div>
//     </div>
//   </div>
// ));
//-------------------
//   let skillList = data.map((item, i) => (
//     <div className="Skills__Card" key={i}>
//       <img src={item.logo1} alt="" />
//       <div className="card-title">{item.name}</div>
//       <ul className="Skills__Stacks">
//         <li>HTML</li>
//         <li>CSS</li>
//         <li>Javascript</li>
//         <li>Something</li>
//         <li>Nothing</li>
//         <li>Everything</li>
//       </ul>
//     </div>
//   ));

//   return (
//     <section className="site-section" id="skills">
//       <div className="container">
//         <h1 className="site-heading"> Skills </h1>{" "}
//         <div className="layout" style={{ padding: "1.5rem" }}>
//           <div className="Skills__Wrapper">{skillList}</div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Skills;
