import React from "react";

const ProjectInfo = ({
  imageUrl,
  title,
  link,
  description,
  technology,
  codeLink,
  imageLink,
  codeButton,
}) => {
  console.log("image", imageUrl);
  console.log(title);
  console.log(link);
  console.log(description);
  console.log(technology);
  console.log(codeLink);
  return (
    <div className="project-info">
      <div className="project-info__info">
        <a className="project-info__visual" href={imageLink} target="_blank">
          <div className="project-info__imagewrapper">
            <div className="project-info__image">
              <img src={imageUrl} alt="" />
            </div>
          </div>
        </a>
        <div className="project-info__text">
          <div className="project-info__title"> {title} </div>
          <div className="project-info__desc"> {description} </div>
          {technology.length > 0 && (
            <div>
              <h2 className="project-info__heading"> Technology used </h2>
              <ul className="project-info__tech">
                {technology.map((t, i) => {
                  return <li> {t} </li>;
                })}
              </ul>
            </div>
          )}
          <div className="project-info__action">
            {link !== "" && (
              <a className="btn btn-primary  bt-lg" target="_blank" href={link}>
                {" "}
                Learn More{" "}
              </a>
            )}
            {codeLink !== "" && (
              <a
                target="_blank"
                className="btn btn-outline-primary bt-lg"
                href={codeLink}
                id="codeLink"
              >
                <img
                  viewBox="0 0 512 512"
                  src={codeButton}
                  alt="Logo"
                  preserveAspectRatio="xMidYMid meet"
                  className="btn-icon"
                />
                &nbsp;&nbsp; Code
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectInfo;
