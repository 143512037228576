import React, { Component } from "react";
import "../sass/work.scss";

class Work extends Component {
  state = {};
  render() {
    const data = this.props.portfolioData;

    let workExp = data.map((experience, i) => (
      <div className="timeline__block" key={i}>
        <div className="timeline__icon">
          <img
            className="timeline__icon__img"
            src={experience.image}
            alt="Logo"
          />
        </div>
        <div
          className="timeline__content"
          id={
            experience.scope == "education" ? "timeline__education_content" : ""
          }
        >
          {/* <h2>
            {experience.title}
            <span className="timeline__content__company">
              {experience.company}
            </span>
          </h2> */}

          <table
            className="timeline__header"
            id={
              experience.scope == "education"
                ? "timeline__education_header"
                : ""
            }
          >
            <tbody>
              <tr className="timeline__header__title">
                <th>
                  <h2>{experience.title}</h2>
                </th>
              </tr>
              <tr className="timeline__header__company">
                <td>
                  <em>{experience.company}</em>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="timeline__content__table">
            <tbody>
              {experience.description.map((desc, i) => (
                <tr className="timeline__content__row" key={i}>
                  <td className="timeline__content__number">{i + 1 + "."}</td>
                  <td className="timeline__content__desc">{desc}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <a
            href={experience.companyWebsite}
            className="timeline__content__link"
            target="_blank"
          >
            Company's Website
          </a>
          <span className="timeline__content__date">{experience.date}</span>
        </div>
      </div>
    ));

    return (
      <section className="site-section" id="work">
        <div className="container">
          <h1 className="site-heading"> Work </h1>{" "}
          <div className="timeline" id="timeline">
            {workExp}
          </div>
        </div>
      </section>
    );
  }
}

export default Work;

// const Timeline = (props) => {
//   const data = props.portfolioData;
//   let expList;
//   expList = data.workExp.map((exp, i) => (
//     <div className="stepper__list" key={i}>
//       <div className="stepper__item">
//         <div className="stepper__step">
//           <div className="stepper__timeline">
//             <div className="stepper__circle"> </div>
//             <div className="stepper__timeline-heading"> {exp.title} </div>
//           </div>
//         </div>
//         <div className="stepper__info">
//           <div className="stepper__body">
//             <h3 className="stepper__title"> {exp.company}</h3>
//             <div className="stepper__sub"> {exp.description} </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   ));
//   return (
//     <section className="site-section  site-section--with-background" id="work">
//       <div className="container">
//         <h1 className="site-heading text-white"> Works </h1>
//         <div className="stepper">{expList}</div>
//       </div>
//     </section>
//   );
// };

// export default Timeline;
